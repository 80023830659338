import $ from "jquery";
import barba from '@barba/core';
var imagesLoaded = require('imagesloaded');

/*mobile menu*/
$('#menu-toggle').click(function (e) {
  $(this).toggleClass('active');
  $('#menu').toggleClass('active');
  gsap.to('#menu-arrow', { rotate: "+=180", duration: 0.3, ease: Elastic });
  e.preventDefault();
});

$('#menu').addClass('invisible');

/*headline scroll animation*/
$(document).on('scroll', function () {
  $('#works .headline-text').css("left", Math.max(0 - 1.0 * window.scrollY / 2) + "px")
  $('#works .headline-text-bg').css("left", Math.max(0 - 1.01 * window.scrollY / 2) + "px")
});

$(document).on('scroll', function () {
  $('#aboutme .headline-text').css("left", Math.max(0 - 1.0 * window.scrollY / 2) + "px")
  $('#aboutme .headline-text-bg').css("left", Math.max(0 - 1.002 * window.scrollY / 2) + "px")
});

$(document).on('scroll', function () {
  $('#contact .headline-text').css("left", Math.max(0 - 1.0 * window.scrollY / 2) + "px")
  $('#contact .headline-text-bg').css("left", Math.max(0 - 1.002 * window.scrollY / 2) + "px")
});

function initblock() {

  $('#passion-link').hover(
    function () {
      $('#passion').addClass('hover')
    },
    function () {
      $('#passion').removeClass('hover')
    }
  )

  /*Active Navigation links*/
  // Cache selectors
  var lastId,
    topMenu = $("#menu"),
    topMenuHeight = topMenu.outerHeight() + 15,
    // All list items
    menuItems = topMenu.find("a"),
    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function () {
      var item = $($(this).attr("href"));
      if (item.length) {
        return item;
      }
    });

  // Bind click handler to menu items
  // so we can get a fancy scroll animation
  menuItems.click(function (e) {
    var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 300);
    e.preventDefault();
  });

  // Bind to scroll
  $(window).scroll(function () {
    // Get container scroll position
    var fromTop = $(this).scrollTop() + topMenuHeight;

    // Get id of current scroll item
    var cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop)
        return this;
    });
    // Get the id of the current element
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
      lastId = id;
      // Set/remove active class
      menuItems
        .parent().removeClass("active")
        .end().filter("[href='#" + id + "']").parent().addClass("active");
    }
  });
}
initblock();

/*smooth scrolling animation */
const math = {
  lerp: (a, b, n) => {
    return (1 - n) * a + n * b
  },
  norm: (value, min, max) => {
    return (value - min) / (max - min)
  }
}

const config = {
  height: window.innerHeight,
  width: window.innerWidth
}

class Smooth {
  constructor() {
    this.bindMethods()

    this.data = {
      ease: 0.1,
      current: 0,
      last: 0
    }

    this.dom = {
      el: document.querySelector('[data-scroll]'),
      content: document.querySelector('[data-scroll-content]')
    }

    this.rAF = null

    this.init()
  }

  bindMethods() {
    ['scroll', 'run', 'resize']
      .forEach((fn) => this[fn] = this[fn].bind(this))
  }

  setStyles() {
    Object.assign(this.dom.el.style, {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      overflow: 'hidden'
    })
  }

  setHeight() {
    document.body.style.height = `${this.dom.content.offsetHeight}px`
  }

  resize() {
    this.setHeight()
    this.scroll()
  }

  preload() {
    imagesLoaded(this.dom.content, (instance) => {
      this.setHeight()
    })
  }

  scroll() {
    this.data.current = window.scrollY
  }

  run() {
    this.data.last = math.lerp(this.data.last, this.data.current, this.data.ease)
    if (this.data.last < .1) {
      this.data.last = 0
    }

    const diff = this.data.current - this.data.last
    const acc = diff / config.width
    const velo = +acc
    const skew = velo * 11

    this.dom.content.style.transform = `translate3d(0, -${this.data.last}px, 0) skewY(${skew}deg)`

    this.requestAnimationFrame()
  }

  on() {
    this.setStyles()
    this.setHeight()
    this.addEvents()

    this.requestAnimationFrame()
  }

  off() {
    this.cancelAnimationFrame()

    this.removeEvents()
  }

  requestAnimationFrame() {
    this.rAF = requestAnimationFrame(this.run)
  }

  cancelAnimationFrame() {
    cancelAnimationFrame(this.rAF)
  }

  destroy() {
    document.body.style.height = ''

    this.data = null

    this.removeEvents()
    this.cancelAnimationFrame()
  }

  resize() {
    this.setHeight()
  }

  addEvents() {
    window.addEventListener('resize', this.resize, {
      passive: true
    })
    window.addEventListener('scroll', this.scroll, {
      passive: true
    })
  }

  removeEvents() {
    window.removeEventListener('resize', this.resize, {
      passive: true
    })
    window.removeEventListener('scroll', this.scroll, {
      passive: true
    })
  }

  init() {
    this.preload()
    this.on()
  }
}
//var mysmooth = new Smooth(); 

/*opening animation*/
if ((window.location.pathname == '/index.html') || (window.location.pathname == '/')) {
  gsap.to("#eye", { rotation: 360, repeat: -1, duration: 1 });
  $(window).on("load", function () {
    var tl = gsap.timeline();
    tl.fromTo(".loading-opening", { ease: "expo", opacity: 1 }, { opacity: 0 }, 0)
      .from(".grid__item--portrait-eye", { ease: "expo", opacity: 0, y: "-25%" }, 0.3)
      .from(".grid__item--portrait-half", { ease: "expo", x: "-25%", duration: 1, opacity: 0 }, 0.3)
      .from(".grid__item--portrait-neck", { ease: "expo", y: "-25%", duration: 1, opacity: 0 }, 0.4)
      .from(".grid__item--portrait-face", { ease: "expo", y: "-10%", duration: 1, opacity: 0 }, 0.5)
      .from(".grid__item--portrait-left", { ease: "expo", x: "25%", duration: 1, opacity: 0 }, 0.6)
      .from(".grid__item--portrait-forehead", { ease: "expo", y: "15%", x: "15%", duration: 1, opacity: 0 }, 0.7)
      .from(".grid__item--splatter", { ease: "expo", opacity: 0 }, 0.7)
      .from(".grid__item--splatter2", { ease: "expo", opacity: 0 }, 0.7)
      .from(".grid__item--bg", { ease: "expo", opacity: 0 }, 0.7)
      .from("#works", { ease: "expo", opacity: 0 }, 0.7)
      .from("#menu", { ease: "expo", y: "-100%", opacity: 0 }, 0.7)
      .from("#hero-text h1", { ease: "expo", y: "-10%", scale: 0.8, opacity: 0 }, 0.7)
      .from("#hero-text h3", { ease: "expo", y: "-10%", scale: 0.8, opacity: 0 }, 0.7);
  });
}

/*barba transitions*/
barba.init({
  transitions: [{
    name: 'opacity-transition',
    leave(data) {
      return gsap.to(data.current.container, { opacity: 0 });
    },
    enter(data) {
      return gsap.from(data.next.container, { opacity: 0 });
    },
    afterEnter() {
      gsap.from('.work-head-description', { duration: 0.5, opacity: 0, y: 200, ease: 'power3' }, 0);
      var tl = gsap.timeline();
      tl.from(".work-head", { duration: 0.5, opacity: 0 }, 0);
      tl.from(".work-head img", { duration: 0.5, opacity: 0, y: 200, ease: 'power3' }, 0);
      tl.from(".work-head-description h1", { duration: 0.5, opacity: 0, y: 200, ease: 'power3' }, 0.1);
      tl.from(".work-head-description h5", { duration: 0.5, opacity: 0, y: 200, ease: 'power3' }, 0.2);
      tl.from(".work-head-description h4", { duration: 0.5, opacity: 0, y: 200, ease: 'power3' }, 0.2);
      tl.from(".work-head-description p", { duration: 0.5, opacity: 0, y: 200, ease: 'power3' }, 0.2);
      tl.from(".work-description_mobile p", { duration: 0.5, opacity: 0, y: 50, ease: 'power3' }, 0.2);
      tl.from(".line", { duration: 0.5, opacity: 0, ease: 'power3' }, 0.25);
      tl.from(".work-content", { duration: 0.5, opacity: 0, ease: 'power3' }, 0.3);
    }
  }],
  views: [{
    namespace: 'theater',
    beforeEnter() {
      $("#video")[0].play();
    },
    namespace: 'bio_hotel',
    beforeEnter() {
      $("#video")[0].play();
    }
  }, {
    namespace: 'home',
    beforeEnter() {
      $('.goback').removeClass('visible');
      $('.goback').addClass('invisible');
      $('#menu, #menu-toggle').removeClass('invisible');
      $('#menu, #menu-toggle').addClass('visible');

    },
    beforeLeave() {
      $('.goback').addClass('visible');
      $('.goback').removeClass('invisible');
      $('#menu, #menu-toggle').addClass('invisible');
      $('#menu, #menu-toggle').removeClass('visible');
    }
  }]
});

barba.hooks.enter(() => {
  window.scrollTo(0, 0);
});

barba.hooks.afterEnter(() => {
  $(function () {
    let isMobile = window.matchMedia("only screen and (max-width: 1300px)").matches;

    if (isMobile) {

    }
    else {
      var mysmooth = new Smooth();
    }
  });
  initblock();
});